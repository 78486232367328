import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'readablePriceNumber'
})
export class ReadablePriceNumberPipe implements PipeTransform {

  transform(value: number | string, decimalPlaces: number = 2): string {
    if (value === null || value === undefined) return '';

    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    return numberValue.toLocaleString('tr-TR', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    });
  }
}
